import React from 'react';

import { Nav } from '../../molecules';
import { Footer } from '../../atoms';

interface AppProps {
  children: any;
  toggle?: string;
}

export const AppLayout: React.FC<AppProps> = ({ children, toggle }) => {
  return (
    <div className="flex bg-black bg-repeat shadow">
      <Nav toggle={toggle}>
        <div className="mt-8">{children}</div>
        <Footer />
      </Nav>
    </div>
  );
};
